import React, {useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

// componenets
import {useTranslation} from 'next-i18next';

// Styles
import 'react-rangeslider/lib/index.css';

import Select from 'react-select';
import Image from "next/legacy/image";

import {useRouter, withRouter} from 'next/router';
// API helper
import {apiHelperBlockchain} from '../../../helper/apiHelper';

import styles from "./Auction.module.scss";

import {ShimmerSimpleGallery} from "react-shimmer-effects";
import dynamic from "next/dynamic";

import ethereumImg from '../../../public/img/sorting.png'
import polygonImg from '../../../public/img/polygon.png'
const LiveAuctionNftList = dynamic(()=>import("./liveAuctionNftList")) ;

const LiveAuctions = (props) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const [all_nft_list, setAllNftList] = useState(props.auctionList);
    const [page, setPage] = useState(1);
    const [totalNft, setTotalNft] = useState(0);
    const size = 4;
    const [clicked_viewMore, setClickedViewMore] = useState(true);
    const [nftLoader, setNftLoader] = useState(false);
    const [network, setNetwork] = useState({
        value: 1,
        label: <>
            <Image src={ethereumImg}
                   alt={t('common:ethereum')}
                   width={16} height={16} />
            <span>{t('common:ethereum')}</span>
        </>
    })
    const networkListDropdown = [
        { value: 1, label: <><Image src={ethereumImg} alt={t('common:ethereum')} width={16} height={16} />
                <span>{t('common:ethereum')}</span></>
        },
        { value: 2, label: <><Image src={polygonImg} alt={t('common:polygon')} width={16} height={16} />
                <span>{t('common:polygon')}</span></>
        }
    ]


    const getAllNFTList = () => {

        if (page === 1) {
            setNftLoader(true)
        }

        let nPass = {
            page,
            size,
            chainIndex: network.value
        };

        if (localStorage.getItem('userData') && localStorage.getItem('userData') !== "") {
            const user = JSON.parse(localStorage.getItem('userData'));
            nPass.user_id = user._id
        }

        let cList1 = [];

        const api = `auctions?page=${page}&size=${size}&chainIndex=${network.value}&user_id=${nPass.user_id}`;
        apiHelperBlockchain(api,  'GET', nPass).then(resp => {
            setNftLoader(false);
            cList1 = resp.data.status ? resp.data.data.nfts : []
            setAllNftList(cList1);
            setTotalNft(resp.data.data.total_nfts);
        }).catch(error => console.error(`Error: ${error}`));
    }

    useEffect(()=>{
        if (all_nft_list.length === totalNft) {
            setClickedViewMore(false);
        }
    },[all_nft_list, totalNft]);

    useEffect(() => {
        getAllNFTList();
    },[network])

    const loadMoreNft = () => {
        router.push('/nft/live-auction/');
    }

    const handleChangeBlockchain = (opt) => {
        setAllNftList([]);
        setClickedViewMore(true);
        setNetwork(opt)
        setPage(1);
    };

    const refreshMetadata = (db_id, new_item) => {

        let nData = all_nft_list;
        const indexOfItemInArray = nData.findIndex(q => q._id === db_id);

        if (indexOfItemInArray > -1) {
            nData[indexOfItemInArray].imageUrl = new_item.imageUrl;
        }
    }

    return (
            <section className={`section ${styles.discoverIndex2} ${props.customClass ? styles.customSection : ''}`}>
                <Container>
                    <Row>
                        <Col sm={6}>
                            {
                                props.title && <h2 className={`h3 ${styles.hot__title}`}>
                                    {t('common:live_auctions')} 🔥
                                </h2>
                            }
                        </Col>
                        <Col sm={6}>
                            <div className="selectMenu">
                                <Select
                                    inputId="network"
                                    value={network}
                                    onChange={handleChangeBlockchain}
                                    options={networkListDropdown}
                                    classNamePrefix="select__Inner"
                                    placeholder={t('common:select')}
                                    isSearchable={false}
                                    instanceId="blockchain"
                                />
                            </div>
                        </Col>
                    </Row>

                    {nftLoader ?
                        <ShimmerSimpleGallery imageHeight={256} row={1} col={4} gap={20} caption />
                        : <LiveAuctionNftList all_nft_list={all_nft_list} refreshMetadata={refreshMetadata} />
                    }

                    { all_nft_list.length >= size
                    && page === 1 && clicked_viewMore &&
                            <div className="text-center" onClick={loadMoreNft}>
                                <div className={`btn buttonStroke loadmoreBtn`}>
                                    {t('common:view_more')}
                                </div>
                            </div>
                    }

                </Container>
            </section>
    )
}
export default withRouter(LiveAuctions);
